import { Component, Vue } from 'vue-property-decorator';
import { IEvents } from '../types/types';

@Component
export default class EventsMixins extends Vue {
  prev(): void {
    (this.$refs.calendar as HTMLElement & { prev: () => void }).prev();
  }
  next(): void {
    (this.$refs.calendar as HTMLElement & { next: () => void }).next();
  }

  unsetEvent(events: Array<IEvents>, title: string): void {
    events.forEach((item, index) => {
      if (item.name === title || item.slug === title) {
        events.splice(index, 1);
      }
    });
  }
}

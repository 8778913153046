











































































































































































































































































































































































































































































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import EventsMixins from '@/mixins/EventsMixins';
import {
  ICalendar,
  IContact,
  IEvents,
  IGroup,
  IPaginate,
  IScheduledEvent,
  ISenderID,
} from '@/types/types';
import { dateFormatForCalendarTypes, loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment';
import { cloneDeep } from 'lodash';

const smsModule = namespace('sms');
const groupModule = namespace('group');
const contactModule = namespace('contact');
const eventsModule = namespace('events');
extend('required', {
  ...required,
  message: 'Field is required',
});
@Component({
  name: 'EventsCalendar',
  components: {
    ConfirmationDialog: loadWidget(`widgets/ConfirmationDialog`),
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    state: {
      get() {
        return this.$store.getters['getDialogState']('add');
      },
      set(value) {
        this.$store.dispatch(
          'updateDialog',
          { idx: 'add', state: value },
          { root: true }
        );
      },
    },
  },
})
export default class EventCalendar extends mixins(EventsMixins, DialogMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @Getter('getWindowSize') windowSize!: number | null;
  @groupModule.Getter('getAllGroups') groups!: ReadonlyArray<IGroup>;
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;
  @contactModule.Getter('getAllContacts') contacts!: ReadonlyArray<IContact>;
  @contactModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage' | 'pages'
  > & { limit: number };
  @eventsModule.Getter('getEventDetails') eventDetails!: IScheduledEvent;

  @Prop({ default: () => [], required: false }) eventsList!: Array<IEvents>;
  @Prop({
    default: () => [
      { id: null, name: '...', color: 'white', slug: -1, start: '2022-02-01' },
    ],
  })
  itemList!: Array<IEvents>;

  @Prop({ default: false })
  isPageLoading!: boolean;
  @Prop({ type: Array, default: () => [] }) times!: string[];
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: null }) addEventFromParentSidebar!: string;

  //newEventDialog = false;
  options = ['Groups', 'Contacts'];
  selectedOption = 0;
  date = new Date().toISOString().substring(0, 10);
  menu = false;
  today: string | null = null;
  focus = '';
  type = 'month';
  typeToLabel: { [key: string]: string } = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
    '4day': '4 Days',
  };
  selectedEvent: any = {};
  selectedElement: EventTarget | null = null;
  selectedOpen = false;
  events: IEvents[] = [];
  domEvent: Event | null = null;
  newEventElement: EventTarget | null = null;
  trackBy = {
    startDate: '',
    endDate: '',
  };
  search = '';
  query = '';

  // event fields
  name = '';
  description = '';
  senderId = '';
  selectedContacts: Array<{ id: number; name: string }> = [];
  selectedGroupsOrContacts:
    | Array<number>
    | Array<{ id: number; phone: number }> = [];
  receivers = false;
  selectedTime = '';
  selectedDate = '';

  @Watch('eventsList')
  onEventChange(payload: Array<IEvents>): void {
    this.events = cloneDeep(payload);
  }

  @Watch('selectedOption')
  onTypeSelected(): void {
    this.selectedGroupsOrContacts = [];
  }

  @Watch('resetFormState')
  onFormStateChange(state: boolean): void {
    if (state) {
      this.name = '';
      this.receivers = false;
      this.selectedGroupsOrContacts = [];
      this.selectedTime = '';
      setTimeout(() => {
        this.$store.dispatch('resetFormValues', false, { root: true });
      }, 3000);
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Watch('addEventFromParentSidebar', {
    deep: true,
  })
  onCreateEventFromParentComponent(date: string): void {
    const newDate = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (date) {
      setTimeout(() => {
        const element = document.getElementById(`day-${newDate}`);
        this.addEvent(element as EventTarget, { date: newDate });
      }, 1);
      this.OnMovingCalendarBaseOnDateChange(date);
    }
  }

  // defining methods for handling calendar and event scheduling
  viewDay(calendar: ICalendar): void {
    const { date } = calendar;
    this.focus = date;
    this.type = 'day';

    if (this.dialog('add')) {
      this.close({ idx: 'add', state: false });
      this.unsetEvent(this.events, '(No title)');
    }
  }

  setToday(): void {
    this.focus = '';
  }

  showEvent(eventEmitted: {
    nativeEvent: Event;
    event: IScheduledEvent;
  }): void {
    const { nativeEvent, event } = eventEmitted;
    const open = () => {
      this.selectedEvent = event;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  @Watch('selectedOpen')
  onDialogOnElementChange(value: boolean): void {
    if (value) {
      this.$router
        .push({
          query: {
            eventId: this.selectedEvent?.id ?? null,
            date: this.selectedEvent?.start
              ? encodeURIComponent(this.selectedEvent?.start)
              : null,
            view: 'event',
          },
        })
        .catch();
    } else {
      this.$router
        .replace({
          query: {},
        })
        .catch();
    }
  }

  @Watch('date')
  onDateOnCalendarDialogChange(date: string): void {
    setTimeout(() => {
      this.newEventElement = document.getElementById(`day-${date}`);
      this.addEvent(this.newEventElement as EventTarget, { date });
    }, 1);
    this.OnMovingCalendarBaseOnDateChange(date);
  }

  // method for moving calendar prev or next base on the date chosen on the dialog
  OnMovingCalendarBaseOnDateChange(date: string): void {
    this.close({ idx: 'add', state: false });
    const dateFormat = dateFormatForCalendarTypes(this.type);
    if (dateFormat) {
      let intervals = moment(date, dateFormat).diff(
        moment(this.trackBy.startDate, dateFormat),
        this.type !== 'month' ? 'days' : 'month'
      );

      let goTo = 0;
      if (this.type === '4day') {
        goTo = Math.floor(intervals / 4);
      } else if (this.type === 'week') {
        goTo = Math.floor(intervals / 7);
      } else {
        goTo = intervals;
      }

      (this.$refs.calendar as HTMLElement & {
        move: (amount: number) => void;
      }).move(Number(goTo));
    }
  }

  addEventWithTime(nativeEvent: Event, event: ICalendar): void {
    const { date, time } = event;
    if (time === '00:00') {
      this.addEvent(nativeEvent, event);
      return;
    }
    const open = () => {
      this.newEventElement = nativeEvent.target;
      setTimeout(() => this.open('add'), 10);
    };

    if (this.dialog('add')) {
      this.close({ idx: 'add', state: false });
      setTimeout(open, 10);
    } else open();

    nativeEvent.stopPropagation();
    this.close({ idx: 'add', state: false });
    this.trackBy.endDate = date;
    this.unsetEvent(this.events, '(No title)');
    this.selectedTime = moment(time, 'hh:mm a').format('hh:mm a');

    setTimeout(() => {
      let body = {
        name: '(No title)',
        id: null,
        slug: 1,
        start: this.formatDate(new Date(date + ' ' + time), true),
        end: this.formatDate(new Date(date + ' ' + time), true),
        color: 'primary',
      };
      this.events.push(body);
      this.date = date;
    }, 1);
  }

  addEvent(
    nativeEvent: Event | EventTarget,
    event: Pick<ICalendar, 'date'>
  ): void {
    const open = () => {
      this.newEventElement =
        (nativeEvent as Event)?.target ??
        ((nativeEvent as unknown) as EventTarget);
      setTimeout(() => this.open('add'), 10);
    };

    if (this.dialog('add')) {
      this.close({ idx: 'add', state: false });
      setTimeout(open, 10);
    } else {
      open();
    }

    if ((nativeEvent as Event)?.target) {
      (nativeEvent as Event)?.stopPropagation();
    }
    this.close({ idx: 'add', state: false });
    this.unsetEvent(this.events, '(No title)');

    setTimeout(() => {
      let body = {
        name: '(No title)',
        id: null,
        slug: 1,
        start: this.formatDate(new Date(event.date), false),
        end: this.formatDate(new Date(event.date), false),
        color: 'primary',
      };
      // set the date in the event dialog
      this.date = event.date;
      this.events.push(body);
    }, 1);
  }

  @Emit('addNewEvent')
  saveEvent(): {
    name: string;
    receivers: string;
    payload: any;
    executionTimestamp: string;
    selectedOption: string;
    groups: Array<any>;
  } {
    const groupOrContacts = (this.selectedGroupsOrContacts as Array<{
      id: number;
    }>)?.map((groupOrContact) => (groupOrContact as { id: number })?.id);
    return {
      name: this.name,
      receivers: this.receivers ? 'ALL' : 'SELECTED',
      payload: {
        from: this.senderId,
        to:
          !this.receivers && Number(this.selectedOption) === 1
            ? (this.selectedGroupsOrContacts as Array<{ phone: number }>)
                ?.map(
                  (groupOrContact) =>
                    (groupOrContact as { phone: number })?.phone
                )
                ?.join(',')
            : '',
        message: this.description,
        type: 'Quick',
      },
      selectedOption: this.selectedOption === 0 ? 'GROUPS' : 'CONTACTS',
      ...(this.selectedOption === 0
        ? {
            selectedGroups: groupOrContacts,
          }
        : { selectedContacts: !this.receivers ? groupOrContacts : [] }),
      executionTimestamp: moment(
        `${this.date} ${this.selectedTime}`,
        'YYYY-MM-DD hh:mm a'
      ).format(),
      groups:
        !this.receivers && Number(this.selectedOption) === 0
          ? groupOrContacts
          : [],
    };
  }

  removeEvent(slug: string): void {
    this.close({ idx: 'add', state: false });
    this.unsetEvent(this.events, slug);
  }

  updateRange(date: { start: ICalendar; end: ICalendar }): void {
    // close dialog if open and user wants to move to another page
    if (this.dialog('add')) {
      this.close({ idx: 'add', state: false });
      this.unsetEvent(this.events, '(No title)');
    }
    const { start, end } = date;
    this.trackBy = { startDate: start?.date, endDate: end.date };
    // set default event to the calendar to allow click events
    this.$store.dispatch('events/list', {
      query: `?activeDate=${start.date}&isPaginated=false`,
    });
  }

  nth(d: number): string {
    return d > 3 && d < 21
      ? 'th'
      : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10];
  }

  rnd(a: number, b: number): number {
    return Math.floor((b - a + 1) * Math.random()) + a;
  }

  formatDate(a: Date, withTime: boolean): string {
    return withTime
      ? `${a.getFullYear()}-${
          a.getMonth() + 1
        }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
      : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
  }

  created(): void {
    this.today = this.formatDate(new Date(), false);
    this.close({ idx: 'add', state: false });
    this.$store.dispatch('sms/listSenderID');
    this.$store.dispatch('group/rawList');
  }

  mounted(): void {
    (this.$refs.calendar as HTMLElement & {
      checkChange: () => void;
    }).checkChange();
  }
}
